.root {
  
}

fieldset.customField,
.customField {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

fieldset.customFieldMultiEnum,
.customFieldMultiEnum {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 40px;
  }
}
