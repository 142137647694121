.root {
  width: 100%;
  height: 100%;
  background-color: var(--colorGrey100);
}

.mapRoot {
  width: 100%;
  height: 100%;

  & :global(.mapboxgl-marker) {
    background-size: contain;
  }
}
