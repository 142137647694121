@import '../../styles/customMediaQueries.css';

.durationLabel {
  font-size: 13px;
  line-height: 16px;
  padding-top: 3px;
  padding-bottom: 5px;
  font-weight: var(--fontWeightMedium);
}

.durationLabel + div {
  font-size: 16px;
  font-weight: var(--fontWeightMedium);
  color: #484848;
}

.durationLabel + div > div {
  min-height: 48px;
  padding-left: 6px;

  & [class*="-placeholder"] {
    color: var(--customGray);
  }
}
