@import '../../styles/customMediaQueries.css';

.root {
  padding: 0 24px 42px;
  width: 100%;
  background-color: var(--colorWhite);
    
  @media (--viewportMedium) {
    padding: 0 36px 80px;
  }
}

.copyBlock {
  max-width: 860px;
  margin: 0 auto;
  
  @media (--viewportMedium) {
    display: flex;
    align-items: center;
  }
}

.copyBlockLink {
  border: 1px solid var(--marketplaceColor);
  padding: 0 15px;
  min-height: 56px;
  line-height: 52px;
  border-radius: 8px;
  
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  
  @media (--viewportMedium) {
    margin: 0;
    width: 75%;
    border-radius: 8px 0 0 8px;
    border-right: none;
  }
}

.copyBlockButton {
  
  @media (--viewportMedium) {
    width: 25%;
    border-radius: 0 8px 8px 0;
  }
}
