@import '../../styles/customMediaQueries.css';

.listTitle {
  margin-top: 60px;
}

.list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  width: 100%;
  margin: 30px 0;
  padding: 0;
}

.listItem {
  width: 100%;
  margin: 0 0 20px;
  padding: 0;

  @media (--viewportMedium) {
    width: calc(50% - 10px);
  }
}

.listItemLink {
  composes: buttonPrimary from global;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  text-align: center;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.notificationContent {
  margin: 30px 0 60px 0;
}
