.root {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;

  height: 75vh;
  max-height: 673px;
  min-height: 50px;
}

.icon {
  width: 28px;
  height: 28px;
  
  stroke: var(--marketplaceColor);
  stroke-width: 3px;
}
