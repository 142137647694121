.root {
  display: inline-block;
  flex-shrink: 0;
  margin: 0 0 0 5px;
}

.tooltip {
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 14px;
  border: 2px solid var(--marketplaceColor);
  border-radius: 50%;
  text-align: center;
  font-size: 10px;
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColor);

  &:hover,
  &:focus {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
    text-decoration: none;
  }
}
