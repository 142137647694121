@import '../../styles/customMediaQueries.css';

.root {
  margin: 0;
}

.twoColumns {
  @media (--viewportMedium) {
    column-count: 2;
  }
}

.item {
  display: flex;
  align-items: center;
  padding: 0;
  margin-bottom: 20px;
}

.checkIcon {
}

.hidden {
  visibility: hidden;
}

.marketplaceFill {
  fill: var(--marketplaceColor);
}

.iconWrapper {
  align-self: baseline;
  margin-right: 8px;

  /* This follows line-height */
  height: 24px;
}

.labelWrapper {
  display: inline-block;
}

.selectedLabel,
.notSelectedLabel {
  font-size: 14px;
  line-height: 18px;
  margin: 0;

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 20px;
  }
}

.selectedLabel {
  font-weight: var(--fontWeightRegular);
}

.notSelectedLabel {
  color: var(--colorGrey300);
  position: relative;
  text-decoration: line-through;
}

.seeMore {
  composes: buttonSecondary from global;
  max-width: 212px;
  min-height: 40px;

  padding: 9px 0;

  line-height: 20px;
  font-weight: var(--fontWeightMedium);
}
