:root {
}

.root {
  & :global(.iti--allow-dropdown) {
    width: 100%;
  }
}

.default {
}
.rounded {
}

.input {
  & .default {
    @apply --defaultField;
  }

  & .rounded {
    @apply --roundedField;
  }
}

.valid {
  & .default {
    @apply --defaultSuccess;
  }

  & .rounded {
    @apply --roundedSuccess;
  }
}

.error {
  & .default {
    @apply --defaultError;
  }

  & .rounded {
    @apply --roundedError;
  }
}

