@import '../../styles/customMediaQueries.css';

.root {
  position: relative;

  width: 100%;
  padding: 24px 24px 42px 24px;
  background-color: var(--marketplaceColorDark);
  
  @media (--viewportMedium) {
    padding: 32px 24px;
  }

  @media (--viewportLarge) {
    padding: 75px 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media (--viewportMedium) {
    margin: 0 auto;
    flex-direction: row;
  }

  @media (--viewportLarge) {
    max-width: calc(var(--contentMaxWidth) + 72px);
    padding: 0 36px;
  }

  @media (--viewportXLarge) {
    max-width: var(--contentMaxWidth);
    padding: 0;
  }
}

.logoLink {
  display: inline-flex;
  margin-bottom: 16px;
}

.logo {
  object-fit: contain;
  object-position: left center;
  width: 69px;
  height: 57px;
}

.organizationCopyright {
  font-size: 14px;
  line-height: 20px;
  margin: 16px 0 0;
  padding: 0;
  display: none;
  
  @media (--viewportMedium) {
    display: block;
  }
}

.copyrightLink {
  color: var(--colorWhite);
  transition: var(--transitionStyleButton);

  &:hover {
    text-decoration: none;
  }
}

/* Desktop and Mobile: links to more information */
.infoLinks {
  
  @media (--viewportMedium) {
    /* flex-basis: 300px; */
  }
}

.list {
  margin-top: 0;
  margin-bottom: 0;
}

.listItem {
  padding: 0;
}

.createListing,
.link {
  /* Font */
  composes: h5 from global;
  line-height: 28px;
  font-weight: var(--fontWeightRegular);
  color: var(--colorWhite);
  transition: var(--transitionStyleButton);

  padding: 0;
  margin: 0 0 16px;

  cursor: pointer;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.referAContactlink {
  display: block;
  margin-bottom: 0;
  
  @media (--viewportMedium) {
    margin-left: 10px;
  }
}


.legalMatters {
  /* Layout */
  display: flex;
  flex-direction: column;
}

.tosAndPrivacy {
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-bottom: 10px;
  }
}

.socialLinks {

}

.socialLinksTitle {
  display: none;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  color: var(--colorWhite);

  @media (--viewportMedium) {
    margin: 0 0 7px 10px;
    display: block;
  }
}

.icon {
  display: inline-block;
  margin: 16px 10px 0 0;
  
  @media (--viewportMedium) {
    margin: 0 0 0 10px;
  }
}

.organizationCopyrightMobile {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--colorGrey300);
  transition: var(--transitionStyleButton);

  /* Tell parent component how much space this one prefers */
  flex-basis: 172px;

  margin-top: 18px;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    display: none;
  }
}
