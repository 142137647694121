@import '../../styles/customMediaQueries.css';

.root {
  padding: 20px 14px;
  background: var(--colorWhite);
  max-width: 100%;
  margin: 0 auto;
  max-width: 328px;
  border-radius: 10px;
  
  @media (--viewportMedium) {
    max-width: 402px;
    margin: 0 0 0 36px;
    padding: 48px;
    border-radius: 38px;
  }

  @media (--viewportLarge) {
    max-width: 456px;
    margin: 0 0 0 10%;
  }

  /* &:before {
    content: "";
    display: block;

    position: absolute;
    left: 50%;
    top: 32px;
    transform: translateX(-50%);

    width: 100%;
    max-width: 75%;
    height: 2px;
    border-radius: 2px;
    background-color: var(--marketplaceColor);
    z-index: 1;

    @media (--viewportMedium) {
      display: none;
    }

  } */
}

.title {
  margin: 0 0 15px;
  font-size: 20px;
  line-height: 1.2;
  
  @media (--viewportMedium) {
    font-size: 32px;
    margin: 0 0 20px;
  }
}

.advancedSearchLocation {

}

.advancedSearchLocationCurrent {

  & input::placeholder {
    color: var(--matterColorDark);
  }
}

.locationAutocompleteInput {
  background: var(--matterColorLight);
  padding: 0 20px;
  /* background-image: url("data:image/svg+xml,%3Csvg width='15' height='19' viewBox='0 0 15 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.5 16.4167L11.625 12.2917C12.4408 11.4759 12.9963 10.4365 13.2213 9.30491C13.4463 8.17337 13.3308 7.00051 12.8892 5.93465C12.4477 4.86878 11.7 3.95777 10.7408 3.31682C9.78149 2.67587 8.6537 2.33377 7.5 2.33377C6.3463 2.33377 5.21851 2.67587 4.25924 3.31682C3.29997 3.95777 2.55229 4.86878 2.11076 5.93465C1.66923 7.00051 1.55368 8.17337 1.77871 9.30491C2.00374 10.4365 2.55926 11.4759 3.375 12.2917L7.5 16.4167ZM7.5 18.7733L2.19667 13.47C1.14779 12.4211 0.433489 11.0847 0.144107 9.62989C-0.145275 8.17504 0.00325571 6.66704 0.570916 5.2966C1.13858 3.92616 2.09987 2.75483 3.33324 1.93072C4.5666 1.10661 6.01665 0.666748 7.5 0.666748C8.98336 0.666748 10.4334 1.10661 11.6668 1.93072C12.9001 2.75483 13.8614 3.92616 14.4291 5.2966C14.9968 6.66704 15.1453 8.17504 14.8559 9.62989C14.5665 11.0847 13.8522 12.4211 12.8033 13.47L7.5 18.7733ZM7.5 9.83335C7.94203 9.83335 8.36595 9.65775 8.67851 9.34519C8.99108 9.03263 9.16667 8.60871 9.16667 8.16668C9.16667 7.72466 8.99108 7.30073 8.67851 6.98817C8.36595 6.67561 7.94203 6.50002 7.5 6.50002C7.05798 6.50002 6.63405 6.67561 6.32149 6.98817C6.00893 7.30073 5.83334 7.72466 5.83334 8.16668C5.83334 8.60871 6.00893 9.03263 6.32149 9.34519C6.63405 9.65775 7.05798 9.83335 7.5 9.83335ZM7.5 11.5C6.61595 11.5 5.7681 11.1488 5.14298 10.5237C4.51786 9.89858 4.16667 9.05074 4.16667 8.16668C4.16667 7.28263 4.51786 6.43478 5.14298 5.80966C5.7681 5.18454 6.61595 4.83335 7.5 4.83335C8.38406 4.83335 9.2319 5.18454 9.85703 5.80966C10.4821 6.43478 10.8333 7.28263 10.8333 8.16668C10.8333 9.05074 10.4821 9.89858 9.85703 10.5237C9.2319 11.1488 8.38406 11.5 7.5 11.5Z' fill='%2345A8D4'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: 20px center; */
  min-height: 40px;
  line-height: 1;

  &::placeholder {
    color: var(--customGray);
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.searchFooter {
  margin: 20px 0 0;
}

.dates {
  width: 100%;
  margin-top: 15px;
  
  @media (--viewportMedium) {
    margin-top: 20px;
  }

  & > div,
  & > div > div {
    display: block;
  }

  & [class*="PopupOpenerButton_label"] {
    width: 100%;
    min-height: 40px;
    text-align: left;
    border: 1px solid var(--customGrayBorderAlt);
    padding: 0 24px 0 20px;
    margin: 0;
    
    /* Background */
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.68579 5.31455C4.86 5.48788 5.14 5.48788 5.31421 5.31455L8.86967 1.75909C9.04344 1.58487 9.04344 1.30399 8.86967 1.13066C8.6959 0.956446 8.41457 0.956446 8.24124 1.13066L5 4.37146L1.75876 1.13066C1.58498 0.956446 1.30366 0.956446 1.13033 1.13066C0.956557 1.30399 0.956557 1.58487 1.13033 1.75909L4.68579 5.31455Z' stroke='%232D2C35'/%3E%3C/svg%3E%0A");
    background-position: center right 16px;
    background-size: 12px;

    font-size: 16px;
    color: var(--customGray);
    
    @media (--viewportMedium) {
      font-size: 14px;
    }

    &:hover,
    &:focus {
      border: 1px solid var(--customBlack);
    }
  }

  & [class*="FilterPopup_popup"] {
    min-width: 100%;
    margin: 0;
    z-index: 1;
    overflow: hidden;

    @media (--viewportMedium) {
      min-width: 360px;
    }
  }

  & [class*="DateRangeController_inputRoot"] {
    margin-top: 0;
    margin-bottom: 0;
  }

  & [class*="FilterForm_buttonsWrapper"] {
    background: var(--marketplaceColor);
    justify-content: space-between;

    & button {
      color: var(--colorWhite);

      &:hover {
        color: var(--marketplaceColorDark);
      }
    }
  }
}

.datesActive {

  & [class*="PopupOpenerButton_label"] {
    color: var(--customBlack);
  }

  & [class*="PopupOpenerButton_labelSelected"] {
    width: 100%;
    text-align: left;
    border: 1px solid var(--customGrayBorderAlt);
    background-color: transparent;

    &:hover,
    &:focus {
      border: 1px solid var(--customBlack);
    }
  }
}

.category {
  position: relative;
  width: 100%;
  margin: 15px 0 20px;
  
  @media (--viewportMedium) {
    margin: 20px 0 30px;
  }
}

.categoryMenuLabel {
  /* Dimensions */
  width: 100%;
  height: 40px;
  margin: 0;

  padding: 0 12px;
  background-color: var(--colorWhite);

  /* Borders */
  border-radius: var(--borderRadiusMedium);
  border: 1px solid var(--customGrayBorderAlt);
  outline: none;

  /* Transition */
  transition: all ease-in-out 100ms;

  padding: 0 24px 0 20px;
  /* box-shadow: 0 2px 3px 0 rgba(216, 220, 230, 0.7); */

  /* Unset user agent styles */
  appearance: none;

  /* Background */
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.68579 5.31455C4.86 5.48788 5.14 5.48788 5.31421 5.31455L8.86967 1.75909C9.04344 1.58487 9.04344 1.30399 8.86967 1.13066C8.6959 0.956446 8.41457 0.956446 8.24124 1.13066L5 4.37146L1.75876 1.13066C1.58498 0.956446 1.30366 0.956446 1.13033 1.13066C0.956557 1.30399 0.956557 1.58487 1.13033 1.75909L4.68579 5.31455Z' stroke='%232D2C35'/%3E%3C/svg%3E%0A");
  background-position: center right 16px;
  background-size: 12px;

  cursor: pointer;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;

  color: var(--customGray);

  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  line-height: 40px;
  
  @media (--viewportMedium) {
    padding: 0 32px 0 16px;
    font-size: 14px;
  }

  &:hover {
    border: 1px solid var(--customBlack);
    /* transition: all ease-in-out 100ms; */
  }
  &:focus {
    background-color: var(--colorWhite);
    border-color: var(--customBlack);
    /* transition: all ease-in-out 100ms; */
  }
  &:disabled {
    opacity: 0.5;

    &:hover {
      cursor: not-allowed;
    }
  }
}

.categoryMenuLabelActive {
  color: var(--customBlack);
}

.categoryMenuContent {
  display: none;
  position: absolute;
  left: 0;
  top: 66px;
  width: 100%;
  background: var(--colorWhite);
  padding: 20px 20px;
  box-shadow: var(--boxShadowPopupLight);
  border-radius: 0 0 var(--borderRadiusMedium) var(--borderRadiusMedium);
  z-index: 1;
}

.categoryOpen {
  position: relative;

  & .categoryMenuLabel {
    border-color: var(--customBlack);
    border-radius: var(--borderRadiusMedium) var(--borderRadiusMedium) 0 0;
  }

  & .categoryMenuContent {
    display: block;
  }
}

.categoryClosed {

}

.categoryItems {

}

.categoryItem {
  display: block;
  margin-bottom: 20px;
}

.categoryButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.categoryButtonsItem {
  font-size: 14px;
  line-height: 16px;
  font-weight: var(--fontWeightMedium);
  cursor: pointer;

  &:hover,
  &:focus {
    color: var(--marketplaceColor);
  }

  &:last-of-type {
    margin-left: 19px;
  }
}

.submitButton {
  min-height: 60px;
} 
