@import '../../../styles/customMediaQueries.css';

.label,
.labelSelected {
  display: inline-block;
  width: auto;
  height: 35px;
  min-height: 35px;
  min-width: 50px;

  padding: 0 18px;
  margin: 0 9px 0 0;

  transition: all var(--transitionStyleButton);
  cursor: pointer;

  /* Font */
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 21px;
  
  /* Button text styles */
  /* Default button text is centered without decorations */
  text-align: center;
  text-decoration: none;

  &:focus,
  &:hover {
    outline: none;
    text-decoration: none;
    border-color: var(--customBlack);
  }

  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
  }

  @media (--viewportMedium) {
    min-width: 120px;
    height: auto;
    min-height: 0;
    margin: 0;
    padding: 7px 18px;
  }
}

.label {
  /* Button borders */
  border: 1px solid var(--customGrayBorder);
  border-radius: var(--borderRadiusMedium);

  /* background-color: var(--colorWhite); */

  &:hover,
  &:focus {
    border-color: var(--customBlack);
  }
  &:disabled {
    border-color: var(--customBlack);
  }

}

.labelSelected {
  /* Button borders */
  border-radius: var(--borderRadiusMedium);
  border: 1px solid var(--marketplaceColor);

  /* Button colors: secondary */
  background-color: var(--marketplaceColor);
  color: var(--colorWhite);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
  &:disabled {
    background-color: var(--colorGrey100);
    color: var(--colorWhite);
  }
}

.labelEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
