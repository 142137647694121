@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  position: relative;

}

.rootLink {
  position: relative;

  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100); /* Loading BG color */
  border-radius: var(--borderRadiusBig);

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusBig);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 10px 0 0 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 6px;
}

.priceValue {
  /* Font */
  font-size: 18px;
  font-weight: var(--fontWeightSemiBold);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  font-size: 16px;
  font-weight: var(--fontWeightRegular);

  color: var(--customBlack);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  font-size: 18px;
  line-height: 24px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--colorGrey700);
  margin: 0 0 6px;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);
  padding: 0;
  margin: 0;
}

.authorLink {
  color: var(--marketplaceColor);
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.priceContainer {
  width: fit-content;
}

.headingContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  
  @media (--viewport1700) {
    flex-direction: row;
    align-items: center;
  }
}

.reviewContainer {
  display: flex;
  align-items: center;
  color: #1a1a1a;
  /*font-weight: 700;*/
}

.rating {
  margin-left: -4px;
  margin-right: 4px;
  
  @media (--viewport1700) {
    margin-left: 10px;
  }
}

.star {
  fill: #c96156;
  height: 14px;
  margin-bottom: 5px;
  margin-right: -3px;
  padding: 0;
}

.newRental {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 6px 10px;
  border: 2px solid var(--marketplaceColor);
  border-radius: 4px;
  background-color: var(--colorWhite);
  z-index: 1;
  font-size: 14px;
  font-weight: var(--fontWeightSemiBold);
  line-height: 1;
}

.navLeft,
.navRight {
  position: absolute;
  width: 36px;
  height: 36px;
  border: 0;
  padding: 0;
  z-index: 1;

  top: calc(50% - 12px);

  /* center content */
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;

    & .navArrowWrapper {
      background-color: rgba(255, 255, 255, 1);
    }
  }
}

.navLeft {
  left: 10px;

  & .navArrowWrapper {
    padding-right: 2px;
  }
}

.navRight {
  right: 10px;

  & .navArrowWrapper {
    padding-left: 2px;
  }
}

.navArrowWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 36px;
  height: 36px;
  border-radius: 2px;
  border: 1px solid var(--colorGrey300);
  background: rgba(255, 255, 255, 0.70);
}

.itemWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    padding-bottom: 340px !important;
  }
}

.itemCentering {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.item {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.noImage {
  border: 1px solid var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
}

.productGallery {
  margin: 0;
  overflow: hidden;

  & :global(.image-gallery) {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    position: relative; 
  }
  
  & :global(.image-gallery-swipe),
  & :global(.image-gallery-slide-wrapper) {
    position: relative;
    max-height: 340px;
    border: none;
  }

  & :global(.image-gallery-slides) {
    line-height: 0;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    text-align: center; 
  }

  & :global(.image-gallery-slide) {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; 
  }

  & :global(.image-gallery.fullscreen-modal) {
    background: #000;
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 5; 
  }
  
  & :global(.image-gallery.fullscreen-modal .image-gallery-content) {
    top: 50%;
    transform: translateY(-50%); 
  }
  
  & :global(.image-gallery-content) {
    position: relative;
    line-height: 0;
    top: 0; 
  }
  
  & :global(.image-gallery-content .image-gallery-slide .image-gallery-image) {
    max-height: calc(100vh - 80px); 
  }
  
  & :global(.image-gallery-content.left .image-gallery-slide .image-gallery-image), 
  & :global(.image-gallery-content.right .image-gallery-slide .image-gallery-image) {
    max-height: 100vh; 
  }
  
  & :global(.image-gallery-slide-wrapper) {
    position: relative; 
  }
  
  
  & :global(.image-gallery-slide-wrapper.left), 
  & :global(.image-gallery-slide-wrapper.right) {
    display: inline-block;
    width: calc(100% - 110px); 
  }
  
  @media (max-width: 768px) {
    & :global(.image-gallery-slide-wrapper.left), 
    & :global(.image-gallery-slide-wrapper.right) {
      width: calc(100% - 87px); } 
  }
  
  & :global(.image-gallery-slide.center) {
    position: relative;  
  }
  
  & :global(.image-gallery-slide .image-gallery-image) {
    width: 100%;
    object-fit: contain; 
  }
  
  & :global(.image-gallery-slide .image-gallery-description) {
    background: rgba(0, 0, 0, 0.4);
    bottom: 70px;
    color: #fff;
    left: 0;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    white-space: normal; 
  }
  
  @media (max-width: 768px) {
  
    & :global(.image-gallery-slide .image-gallery-description) {
      bottom: 45px;
      font-size: .8em;
      padding: 8px 15px; 
    }
  }

  & :global(.image-gallery-bullets) {
    bottom: 20px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 80%;
    z-index: 4;
  }

  & :global(.image-gallery-bullets .image-gallery-bullets-container) {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  & :global(.image-gallery-bullets .image-gallery-bullet) {
    appearance: none;
    background-color: transparent;
    border: 1px solid var(--colorGrey300);
    border-radius: 50%;
    box-shadow: 0 1px 0 #1a1a1a;
    cursor: pointer;
    display: inline-block;
    margin: 0 3px;
    outline: none;
    padding: 3px;
    transition: background .2s ease-out; 
  }

  & :global(.image-gallery-bullets .image-gallery-bullet.active) {
    background: var(--colorWhite);
  }
}

.productGallerySingle {
  overflow: hidden;

  & :global(.image-gallery-bullets .image-gallery-bullet) {
    display: none;
  }
}

